<template>
  <div class="wrapper">
    <div class="clearfix electric-select">
      <el-form :inline="true">
        <el-form-item label="创建人">
          <el-input v-model="createman" clearable></el-input>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="titledesc" clearable></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="discription" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search()">
            <i class="el-icon-search"></i>
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      :data="tableData"
      border
      tooltip-effect="dark myTooltips"
      id="table-out"
      style="width: 100%">
      <el-table-column
        prop="titledesc"
        label="标题">
      </el-table-column>
      <el-table-column
        prop="discription"
        label="内容"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="createman"
        label="创建人">
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间">
      </el-table-column>
      <el-table-column label="操作" align="center" height show-overflow-tooltip>
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="lookDetail(scope.row)">查看</el-button>
          <el-button type="danger" size="small" @click="deleteFn(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="block">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize"
        layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum"></el-pagination>
    </div>


    <el-dialog title="详情" :visible.sync="dialogFormVisible">
      <el-form :model="form" :disabled="true">
        <el-form-item label="标题" :label-width="formLabelWidth">
          <el-input v-model="form.titledesc" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="内容" :label-width="formLabelWidth">
          <el-input v-model="form.discription" type="textarea" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="图片" :label-width="formLabelWidth">
          <el-row>
            <el-image v-for="(url,index) in form.picurl" :key="index" 
              style="width: 100px; height: 100px; margin-right: 5px;"
              :src="url" 
              :preview-src-list="form.picurl">
            </el-image>
          </el-row>
        </el-form-item>
        <el-form-item label="创建人" :label-width="formLabelWidth">
          <el-input v-model="form.createman" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="创建时间" :label-width="formLabelWidth">
          <el-input v-model="form.create_time" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogFormVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FileSaver from "file-saver";
import XLSX from "xlsx";
import {
  appNineSpecialPage,
  appNineSpecialDel
	} from '@/api/ninesite'
export default {
  name:"",
  components:{},
  props:{},
  data(){
    return {
      tableData: [],
      createman: '',
      titledesc: '',
      discription: '',	
      currentPage: 1,
      fenye: {
        pagesizes: [10, 20, 50],
        pagesize: 10,
        totalnum: 0
      },
      dialogFormVisible: false,
      form: {},
      formLabelWidth: '120px'
    }
  },
  watch:{},
  created(){},
  mounted(){
    this.search();
  },
  computed:{},
  methods:{
    search(){
      this.currentPage = 1;
      this.getList();
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.fenye.pagesize = val;
      this.search;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    lookDetail(row){
      this.form = row;
      this.dialogFormVisible = true;
    },
    //删除
    deleteFn(row){
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        appNineSpecialDel({
          manid: this.GLOBAL.adminId(),
          id: row.id,
        }).then(res => {
          if(res.result == 200){
            this.$message.success('操作成功');
            this.search()
          }else{
            this.$message.success(res.description);
          }
        })
      }).catch(() => {

      });
    },
    getList(){
      appNineSpecialPage({
        manid: this.GLOBAL.adminId(),
        createman: this.createman,
        titledesc: this.titledesc,
        discription: this.discription,
        page: this.currentPage,
				pageSize: this.fenye.pagesize,
      }).then(res => {
        console.log(res)
        let list = res.detail.list;
        this.tableData = list;
        this.fenye.totalnum = res.detail.totalRow;
      })
    },
    exportExl() {
      let xlsxParam = {
        raw: true
      }
      let wb = XLSX.utils.table_to_book(
        document.querySelector('#table-out'),
        xlsxParam
      )
      let wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      })
      try {
        FileSaver.saveAs(
          new Blob([wbout], {
            type: 'application/octet-stream'
          }),
          '辖区单位类型数量汇总.xlsx'
        )
      } catch (e) {
        if (typeof console !== 'undefined') console.log(e, wbout)
      }
      return wbout
    },
  }
}
</script>

<style lang="scss" scoped>
  .block{
    margin: 20px 0;
  }
</style>

<style>
  .myTooltips{ 
    width: 30%
  }
</style>